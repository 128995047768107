import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import API from 'services/api'
import { ButtonNarrow } from 'components/common/buttons'
import useSSE from 'components/common/hooks/useSSE'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import useApi from 'components/common/hooks/useApi'
import { useDispatch } from 'react-redux'
import itAdminSlice from 'redux/slices/itAdmin'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.integrations_page.integration_card.zoho.test_integration')

const statusSvgs = {
  waiting: '/images/syncIcon.svg',
  success: '/images/checkIcon.svg',
  error: '/images/xIcon.svg',
}

const ZohoSettings = ({ supportEmailAddress }) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const isClearyAdmin = currentUser.permissions.clearyAdmin

  const [updateSupportEmailAddress, { isLoading: isUpdatingSupportEmailAddress }] = useApi(
    API.admin.ticketing.zoho.updateSupportEmailAddress,
    {
      onSuccess: () => {
        dispatch(itAdminSlice.asyncActions.admin.fetchData())
      },
    }
  )

  const [emailAddress, setEmailAddress] = useState(supportEmailAddress)
  const [isLoading, setIsLoading] = useState(false)
  const [isFinishedTesting, setIsFinishedTesting] = useState(false)
  const [state, setState] = useState({
    webhooks: 'waiting',
    fetchDepartments: 'waiting',
    createTicket: 'waiting',
    createComment: 'waiting',
    createMessage: 'waiting',
  })

  const { eventSource } = useSSE('/api/admin/ticketing/zoho/test_integration', {
    options: {
      headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': API.csrfToken() },
      method: 'POST',
      start: false,
    },
    listeners: {
      data: (event) => {
        const data = JSON.parse(event.data)

        if (data.state === 'all') {
          setIsLoading(false)
          setIsFinishedTesting(true)

          if (data.status === 'error') {
            const waitingKeys = Object.keys(state).filter(key => state[key] === 'waiting')

            setState(prevState => waitingKeys.reduce((acc, key) => ({ ...acc, [key]: 'error' }), prevState))
          }

          return
        }

        setState(oldState => ({ ...oldState, [data.state]: data.status }))
      },
      error: () => {
        setIsLoading(false)
        setIsFinishedTesting(true)
      },
    },
  })

  const onTestIntegration = () => {
    setIsLoading(true)
    eventSource?.current?.stream()
  }

  const onSaveSupportEmailAddress = () => {
    updateSupportEmailAddress(emailAddress)
  }

  return (
    <div className='ZohoSettings mt-2'>
      <div>
        <label htmlFor='support-email-address' className='text-small text-secondary'>
          {I18N('support_email_address')}
        </label>
        <div className='d-flex gap-1'>
          <input
            id='support-email-address'
            type='text'
            className='form-control'
            value={emailAddress}
            onChange={e => setEmailAddress(e.target.value)}
          />

          <ButtonNarrow
            onClick={onSaveSupportEmailAddress}
            showLoadingSpinner={isUpdatingSupportEmailAddress}
            disabled={_.isEmpty(emailAddress) || emailAddress === supportEmailAddress}
          >
            {I18NCommon('save')}
          </ButtonNarrow>
        </div>
      </div>

      <div className='mt-4'>
        <div className='d-flex justify-content-between'>
          <ButtonNarrow
            onClick={onTestIntegration}
            showLoadingSpinner={isLoading}
            disabled={_.isEmpty(supportEmailAddress) || isFinishedTesting}
          >
            {I18N('button')}
          </ButtonNarrow>

          {isClearyAdmin && (
            <Link to='/admin/ticketing/integrations/logs' target='_blank'>
              See logs
            </Link>
          )}
        </div>

        {(isLoading || isFinishedTesting) && (
          <div className='ZohoSettings__integration-status mt-2'>
            {Object.entries(state).map(([key, value]) => (
              <div key={key} className='d-flex justify-content-between w-50'>
                <p
                  data-testid={`${key}-status`}
                  className={classNames('m-0 text-small', value, {
                    'text-muted': value === 'waiting',
                    'text-success': value === 'success',
                    'text-danger': value === 'error',
                  })}
                >
                  {I18N(`states.${key}`)}
                </p>

                <CdnSvg src={statusSvgs[value]} className={value} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ZohoSettings
